import React from 'react'
import './style.scss'

import madsallygamesLogo from '../images/madsallygames-logo.png'

const Navbar = () => (
  <div className="container" style={{ padding: '1rem', textAlign: 'center' }}>
    <a href="/">
      <img
        src={madsallygamesLogo}
        alt="Mad Sally Games"
        style={{ width: 256 }}
      />
    </a>
  </div>
)

export default Navbar
