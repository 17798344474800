import React from 'react'
import './style.scss'

const Footer = () => (
  <footer className="footer center">
    <div className="content has-text-centered">
      <p>&copy; 2020 Mad Sally Games, LLC. All Rights Reserved.</p>
    </div>
  </footer>
)

export default Footer
